<script>
  import { onMount, createEventDispatcher, getContext } from 'svelte'
  import { createPaypalButton, getDeviceData } from '@/util/BraintreeClient'
  import Tracker from '@/util/tracker.js'

  export let tokenizationKey

  export let layout = 'vertical'
  export let shape = 'rect'
  export let height = 50
  export let loading = false
  $: style = { layout, shape, height }

  let extraClasses
  export { extraClasses as class }

  const dispatch = createEventDispatcher()
  const expressButtons = getContext('express_buttons')

  onMount(async () => {
    try {
      const button = await createPaypalButton(
        tokenizationKey,
        (payload) => {
          dispatch('submit', { payload })
        },
        {
          style,

          onClick: () => {
            dispatch('click')
          },
        },
      )
      if (!button) {
        console.log('Paypal unavailable')
        dispatch('unavailable')
        return
      }
      loading = false

      await button.render('#paypal-button-container')

      const device_data = await getDeviceData(tokenizationKey)
      dispatch('load', { device_data })
    } catch (error) {
      expressButtons?.reportAvailability('paypal', false)
      Tracker.track('PaypalButton #init error', {
        message: error,
        url: window.location.toString(),
      })
    }
  })
</script>

<div class={`h-[50px] ${extraClasses || ''}`} class:hidden={loading}>
  <div class="h-auto" id="paypal-button-container" on:mockCheckout />
</div>
