<script>
  import { createEventDispatcher, getContext, onMount } from 'svelte'

  import { clickOrEnterHandler } from '@/util/svelte.js'
  import { createApplePay, getDeviceData } from '@/util/BraintreeClient'
  import Tracker from '@/util/tracker.js'

  export let tokenizationKey
  export let pill = false

  const dispatch = createEventDispatcher()

  let applePay = null

  const expressButtons = getContext('express_buttons')
  expressButtons?.register('apple_pay')

  onMount(async () => {
    try {
      applePay = await createApplePay(tokenizationKey)

      if (!applePay) {
        console.warn('Apple Pay is not supported')
        expressButtons?.reportAvailability('apple_pay', false)
        try {
          Tracker.track('braintree express payment unavailable', {
            payment_type: 'Apple Pay',
          })
        } catch (error) {
          console.error(
            'braintree express payment unavailable tracking failed Apple Pay',
            error,
          )
        }
        return
      }

      expressButtons?.reportAvailability('apple_pay', true)

      const deviceData = await getDeviceData(tokenizationKey)
      dispatch('load', { deviceData })
      try {
        Tracker.track('braintree express payment available', {
          payment_type: 'Apple Pay',
        })
      } catch (error) {
        console.error(
          'braintree express payment available tracking failed for Apple Pay',
          error,
        )
      }
    } catch (error) {
      expressButtons.reportAvailability('apple_pay', false)
      Tracker.track('ApplePayButton #init error', {
        message: error,
        url: window.location.toString(),
      })
      applePay = null
    }
  })

  async function click() {
    if (!applePay) {
      return
    }

    dispatch('click', { applePay })
  }
</script>

<style>
  .apple-pay-button {
    appearance: -apple-pay-button;
    -apple-pay-button-type: plain;
    -apple-pay-button-style: black;
  }
</style>

{#if applePay != null}
  <div
    class="apple-pay-button w-full h-[50px] cursor-pointer"
    class:rounded-full={pill}
    role="button"
    use:clickOrEnterHandler={click}
  />
{/if}
