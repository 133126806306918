<script>
  export let shape = 'rect'
  export let width = '100'
  export let height = 12
  export let animation = 'pulse'

  const widthVariants = {
    25: 'w-1/4',
    50: 'w-1/2',
    75: 'w-3/4',
    100: 'w-full',
  }

  const shapeVariants = {
    rect: 'rounded',
    circle: 'rounded-full',
    rounded: 'rounded-3xl',
    pill: 'rounded-3xl',
  }

  const heightVariants = {
    12: 'h-3',
    16: 'h-4',
    20: 'h-5',
    24: 'h-6',
    50: 'h-[50px]',
    55: 'h-[55px]',
  }

  const animationVariant = {
    pulse: 'animate-pulse',
    none: '',
  }

  const baseClasses = `${widthVariants[width]} ${heightVariants[height]} ${shapeVariants[shape]} ${animationVariant[animation]} bg-grey-200`
</script>

<div class={baseClasses} />
