<script>
  import { createEventDispatcher, getContext } from 'svelte'

  import { createGooglePay, getDeviceData } from '@/util/BraintreeClient'
  import Tracker from '@/util/tracker.js'

  export let tokenizationKey
  export let radius

  const dispatch = createEventDispatcher()

  let container

  const expressButtons = getContext('express_buttons')
  expressButtons?.register('google_pay')

  async function init() {
    try {
      if (!window.google.payments.api.UseCanMakePaymentResultFromPayjs) {
        console.debug('Google Pay support is not available.')
        expressButtons.reportAvailability('google_pay', false)
        try {
          Tracker.track('braintree express payment unavailable', {
            payment_type: 'Google Pay',
          })
        } catch (error) {
          console.error(
            'braintree express payment unavailable tracking failed for Google Pay',
            error,
          )
        }
        return
      }

      const options = {}
      if (radius != null) {
        options.buttonRadius = radius
      }

      const button = await createGooglePay(tokenizationKey, click, options)
      if (!button) {
        try {
          Tracker.track('braintree express payment unavailable', {
            payment_type: 'Google Pay',
          })
        } catch (error) {
          console.error(
            'braintree express payment unavailable tracking failed for Google Pay',
            error,
          )
        }
        return expressButtons.reportAvailability('google_pay', false)
      }

      container.appendChild(button)

      const deviceData = await getDeviceData(tokenizationKey)
      dispatch('load', { deviceData })
      expressButtons.reportAvailability('google_pay', true)

      try {
        Tracker.track('braintree express payment available', {
          payment_type: 'Google Pay',
        })
      } catch (error) {
        console.error(
          'braintree express payment available tracking failed for Google Pay',
          error,
        )
      }
    } catch (error) {
      expressButtons?.reportAvailability('google_pay', false)
      Tracker.track('GooglePayButton #init error', {
        message: error,
        url: window.location.toString(),
      })
    }
  }

  function click(ev, detail) {
    ev.preventDefault()
    dispatch('click', detail)
  }
</script>

<svelte:head>
  <script src="https://pay.google.com/gp/p/js/pay.js" on:load={init}></script>
</svelte:head>

<div>
  <div bind:this={container} style="height: 50px;" />
</div>
