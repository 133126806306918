import { derived, writable } from 'svelte/store'

// This is a store that is exposed via context to Express Checkout buttons.
// Express checkout buttons are expected to call `register` when they are mounted
// and `reportAvailable` once they are available for use (if they can be used).
export class ExpressButtons {
  constructor() {
    this.state = writable({})
  }

  register(checkoutOption) {
    this.state.update(($v) => ({ ...$v, [checkoutOption]: null }))
  }

  reportAvailability(checkoutOption, available) {
    this.state.update(($v) => ({ ...$v, [checkoutOption]: available }))
  }

  get registeredCount() {
    return derived(this.state, ($state) => Object.entries($state).length)
  }

  get loading() {
    return derived(
      this.state,
      ($state) => Object.values($state).filter((v) => v === null).length > 0,
    )
  }

  get availableCount() {
    return derived(
      this.state,
      ($state) => Object.values($state).filter((v) => v).length,
    )
  }
}

export default ExpressButtons
